
<script lang="ts" setup>
import { computed, ref } from 'vue'
import TmInput from '@/tmui/components/tm-input/tm-input.vue'

interface IProps {
  /** input占位 */
  placeholder?: string
  /** input绑定modelValue */
  modelValue?: string
  /** 显示搜索按钮 */
  searchBtn?: boolean
  /** 禁用 */
  disabled?: boolean
  /** 自动获取焦点 */
  focus?: boolean
  focusColor?: string
}

// 接受父组件参数，配置默认值
const props = withDefaults(defineProps<IProps>(), {
  placeholder: '请输入搜索',
  modelValue: '',
  searchBtn: true,
  disabled: false,
  focus: false,
  focusColor: 'primary',
})

const emits = defineEmits(['confirm', 'search', 'update:modelValue', 'click'])

const searchModelValue = ref('')
const searchValue = computed({
  get: () => props.modelValue,
  set: (val) => {
    searchModelValue.value = val
    emits('update:modelValue', val)
  },
})

const submit = () => {
  if (props.disabled) return
  emits('update:modelValue', searchModelValue.value)
  emits('confirm', searchModelValue.value)
}

const search = () => {
  if (props.disabled) return
  emits('update:modelValue', searchModelValue.value)
  emits('search', searchModelValue.value)
}

const inputChange = () => {
  searchModelValue.value = searchValue.value
  emits('update:modelValue', searchValue.value)
}
</script>

<template>
  <view class="search-head">
    <view class="flex" style=" align-items: center;width: 100%;">
      <view :style="searchBtn?'width: 90%;':'width: 100%;'">
        <tm-input
          v-model="searchValue"
          color="white"
          :focus-color="focusColor"
          :placeholder="placeholder"
          placeholder-style="font-size: 25rpx;color:#999999"
          :focus="focus"
          :disabled="disabled"
          v-bind="$attrs"
          @confirm="search"
          @input="inputChange"
        >
        </tm-input>
      </view>
      <tm-button
        v-if="searchBtn"
        :margin="[0]"
        :width="100"
        :height="70"
        transprent
        text
        :shadow="0"
        size="normal"
        label="搜索"
        @click="submit"
      ></tm-button>
      <slot name="right"></slot>
    </view>

    <!-- <input
      v-model="searchValue"
      class="input-view"
      :placeholder="placeholder"
      :disabled="disabled"
      :focus="focus"
      placeholder-style="font-size: 26rpx;color:#999999"
      @confirm="submit"
      @input="inputChange"
    > -->

  </view>
</template>

<style lang="scss" scoped>
.search-head {
  position: relative;
  display: flex;
  .search-icon {
    width: 30rpx;
    height: 30rpx;
  }
  .input-view {
    width: 600rpx;
    height: 68rpx;
    padding-left: 70rpx;
    font-size: 28rpx;
    background: #f9f9f9;
    border-radius: 10rpx;
    box-sizing: border-box;
  }
}
</style>
