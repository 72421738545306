import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import * as DictAPI from '@/api/modules/utils'
import { IDict } from '@/models/index'

/**
 * @description 缓存字典
 * @author
 */
export const useDictStore = defineStore('dictStore', () => {
  const state = reactive<{
    map: Map<string, Array<IDict.Model>>
		queue: Map<string, boolean>;
  }>({
    map: new Map(),
    queue: new Map()
  })

  const getDictItem = computed(() => {
    return (dictType: string, value: string | string[]) => {
      if (!dictType || !value) return
      const dictList = state.map.get(dictType)
      if (dictList && value) {
        if (typeof value === 'string') {
          return dictList.filter(item => value.split(',').includes(item.value))
        } else {
          return dictList.filter(item => value.includes(item.value))
        }
      } else {
        return []
      }
    }
  })

  const getDict = async (params: { type: string | null }, exclude?: string[]): Promise<Array<IDict.Model>> => {
    if (!params.type) return []
    if (state.queue.has(params.type)) {
      const dictList = state.map.get(params.type)
      // 获取缓存
      return exclude ? dictList!.filter(item => !exclude.includes(item.value)) : dictList!
    } else {
      // 加入队列
      state.queue.set(params.type, true)
      const t = new Date().getTime()
      const { data } = await DictAPI.getDictList({ type: params.type, t: t + Math.floor(Math.random() * 100 + 1) })
      state.map.set(params.type, data!)
      return exclude ? data!.filter(item => !exclude.includes(item.value)) : data!
    }
  }

  return {
    getDictItem,
    getDict
  }
})
