<script setup lang="ts">
import { onLaunch, onShow, onHide } from '@dcloudio/uni-app'
// import { useAuthStore } from '@/store/modules/system/auth'
import { removeInterceptor, setupInterceptors } from '@/utils/interceptors'
import { useRouterStore } from '@/store/modules/system/router'
// import { routerBeforeEach } from '@/utils/router/interceptor'

onLaunch(() => {
  console.log('App Launch')
  removeInterceptor()

  setupInterceptors()
  const appStore = useRouterStore()
  appStore.initialize()
})
onShow(() => {

  // const { hash } = window.location
  // routerBeforeEach(hash.slice(1))

  // const authStore = useAuthStore()
  // authStore.initToken()
  console.log('App Show')
})
onHide(() => {
  console.log('App Hide')
})
</script>
<style lang="scss">
@import "@/static/scss/common.scss";




@import "./tmui/scss/noNvue.css";


// uni-page-head {
//   display: none;
// }

</style>
