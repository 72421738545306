import { useWeChat } from '@/hooks/useWeChat'
import { routerInterceptor, routerRemoveInterceptor } from '@/utils/router/interceptor'

export function setupInterceptors() {
  routerInterceptor()
  // #ifdef H5
  const { getRedirectUri } = useWeChat()
  const { origin, pathname, search } = window.location
  const path = origin + pathname
  if (getRedirectUri.value === path) {
    location.replace(origin + '/#/pages/login/index' + search)
  }
  // #endif
}

export function removeInterceptor() {
  routerRemoveInterceptor()
}
