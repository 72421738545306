import { isObject } from '@/utils/is'

/**
 * 深度合并
 * @param src
 * @param target
 */
export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string
  for (key in target) {
    src[key] = isObject(src[key])
      ? deepMerge(src[key], target[key])
      : (src[key] = target[key])
  }
  return src
}

/**
 * @description 微信环境判断
 * @author wx
 */
export function isWechat() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}
