<script lang="ts" setup>
interface IProps {
    text: string
}

const props = defineProps<IProps>()

/** 复制 */
const clipboardClick = () => {
  if (!props.text) return
  uni.setClipboardData({
    data: props.text,
    success: () => {
      uni.showToast({
        title: '复制成功',
        icon: 'success',
      })
    },
  })
}

</script>
<template>
  <text class="text-word" @click.stop="clipboardClick">{{ props.text || '无' }}</text>
</template>

<style lang="scss" scoped>
</style>

