import http from '@/api'
import { ADMIN } from '@/api/config/servicePort'
import { IDict, IAdvert } from '@/models'

// * 获取字典列表
export const getDictList = (params: { type: string; t?: number }) => {
  const header = {
    isToken: false,
  }
  return http.get<Array<IDict.Model>>(ADMIN + `/dict/type/${params.type}`, params, { header, bfLoading: false })
}

// * 获取广告位列表
export const getAdvertList = (params: { locationNo: string; cityId?: string }) => {
  const header = {
    isToken: false,
  }
  return http.get<Array<IAdvert.Model>>('' + `/cms/app/advert/list`, params, { header })
}

/**
 * 获取微信openid
 * @param code
 * @returns
 */
export const getWeChatOpenId = (code: string) => {
  const header = {
    isToken: false,
  }
  return http.get<string>(ADMIN + `/app/user/openId/${code}`, {}, { header })
}

