import { defineStore } from 'pinia'

interface AuthState {
  token?: string;
  refreshToken?: string;
}

export const useAuthStore = defineStore({
  id: 'authStore',
  state: (): AuthState => ({
    token: undefined,
    refreshToken: undefined,
  }),
  getters: {
    getToken: (state) => state.token,
    isLogin: (state): boolean => !!state.token,
    getAuthorization: (state) => {
      return state.token ? { Authorization: `Bearer ${state.token}` } : {}
    },
  },
  actions: {
    setToken(token: string | undefined) {
      this.token = token
    },
    setRefreshToken(token: string | undefined) {
      this.refreshToken = token
    },
    /**
     * @description 登出
     */
    // async loginOut(): Promise<any> {
    //   try {
    //     // const res = await logout()
    //     removeCache(TOKEN_KEY)
    //     this.setToken(undefined)
    //     return Promise.resolve(res)
    //   } catch (err: any) {
    //     return Promise.reject(err)
    //   }
    // },
    /**
     * @description 刷新token
     */
    // async refreshToken(): Promise<LoginModel> {
    //     try {
    //         const { data } = await refreshToken();
    //         this.setToken(data.token);
    //         return Promise.resolve(data);
    //     } catch (err: any) {
    //         return Promise.reject(err);
    //     }
    // },
  },
  persist: false
})
