import { createSSRApp } from 'vue'
import * as Pinia from 'pinia'
import App from './App.vue'
import { setupStore } from '@/store/index'
import tmui from './tmui'
// components
import UseAppBase from '@/components/UseAppBase/index.vue'
import UseImage from '@/components/UseImage/index.vue'
import UseDictLabel from '@/components/UseDict/label.vue'
import UseDictSelect from '@/components/UseDict/index.vue'
import UseSearch from '@/components/UseSearch/index.vue'
import UseClipboardText from '@/components/UseClipboardText/index.vue'

export function createApp() {
  const app = createSSRApp(App)
  setupStore(app)
  // ui
  app.use(tmui)
  // component
  app.component('UseAppBase', UseAppBase)
  app.component('UseImage', UseImage)
  app.component('UseDictLabel', UseDictLabel)
  app.component('UseDictSelect', UseDictSelect)
  app.component('UseSearch', UseSearch)
  app.component('UseClipboardText', UseClipboardText)
  return {
    app,
    Pinia
  }
}
