// 文档 https://uniajax.ponjs.com/guide/interceptor

import ajax, { AjaxInstance, AjaxRequestConfig } from 'uni-ajax'
import { ResultEnum } from '@/enums/httpEnum'
import { ResultData } from '@/api/interface'
import { useAuthStore } from '@/store/modules/system/auth'
import { useRoute } from '@/hooks/useRouter'
import { useGlobalStore } from '@/store'
import { jumpLogin } from '@/utils/router/constant'

interface RequestConfig extends AjaxRequestConfig {
  bfLoading?: boolean;
  loadingText?: string;
}

let isLogin = true

let isJump = false

// loading对象
let loading: boolean

// 当前正在请求的数量
let needLoadingRequestCount = 0

// 显示loading
const showLoading = (method: string | undefined, loadingText?:string) => {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = true
    const { setPageLoading } = useGlobalStore()
    setPageLoading(true)
    if (loadingText) {
      uni.showLoading({ title: loadingText })
    } else {
      const title = method === 'GET' ? '加载中' : '数据提交中'
      uni.showLoading({ title })
    }
  }
  needLoadingRequestCount++
}

// 隐藏loading
const hideLoading = () => {
  needLoadingRequestCount--
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0) // 做个保护
  if (needLoadingRequestCount === 0) {
    // 关闭loading
    toHideLoading()
  }
}

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
const toHideLoading = () => {
  uni.$tm.u.debounce(() => {
    if (loading) {
      uni.hideLoading()
    }
    loading = false
    const { setPageLoading } = useGlobalStore()
    setPageLoading(false)
  })
}

const config: RequestConfig = {
  baseURL: import.meta.env.VITE_API_URL,
  // 设置超时时间（10s）
  timeout: ResultEnum.TIMEOUT as number,
  // 跨域时候允许携带凭证
  withCredentials: true,
  // 打开loading
  bfLoading: true,
  loadingText: '',
  header: {
    'CLIENT-TOC': 'Y'
  }
}

class RequestHttp {
  // 创建请求实例
  service: AjaxInstance<RequestConfig>;

  public constructor(config: RequestConfig) {
    this.service = ajax.create(config)
    // 添加请求拦截器
    this.service.interceptors.request.use(
      (config: RequestConfig) => {
        const { token } = useAuthStore()
        const isToken = (config.header || {}).isToken === false
        if (!token && !isToken) {
          isJump = true
          const route = useRoute()
          console.log('请求拦截跳转')
          jumpLogin(route.path!, route.query)
          return Promise.reject({
            config,
            errMsg: 'request:fail token',
          })
        }
        // 在发送请求前做些什么
        if (config.bfLoading !== false) {
          showLoading(config.method, config.loadingText)
        }
        if (token && !isToken) {
          isLogin = true
          config.header!['Authorization'] = 'Bearer ' + token // token
        }
        // AppId
        config.header!['AppId'] = import.meta.env.VITE_WX_APP_ID
        return config
      },
      (error) => {
        if (config.bfLoading !== false) {
          hideLoading()
        }
        uni.showToast({
          title: '请求超时！请您稍后重试',
          icon: 'none',
          duration: 2000,
        })
        return Promise.reject(error)
      }
    )

    // 添加响应拦截器
    this.service.interceptors.response.use(
      (response) => {
        console.log(response)
        const config = response.config as RequestConfig
        if (config.bfLoading !== false) {
          hideLoading()
        }
        if (response.data.code && response.data.code !== 0) {
          uni.showToast({
            title: response.data.msg,
            icon: 'none',
            duration: 2000,
          })
          return Promise.reject(response.data)
        }
        // 对响应数据做些什么
        return response.data
      },
      (error) => {
        console.log(error)
        const { setToken } = useAuthStore()
        if (config.bfLoading !== false) {
          hideLoading()
        }
        if (!error.statusCode) {
          uni.showToast({
            title: '请求超时！请您稍后重试',
            icon: 'none',
            duration: 2000,
          })
        } else if (error.statusCode === 500) {
          uni.showToast({
            title: error.data.msg || '网络错误，请稍后再试',
            icon: 'none',
            duration: 2000,
          })
          return Promise.reject(error)
        } else if (error.statusCode === 424) {
          if (isLogin) {
            isLogin = false
            uni.showToast({
              title: '登录失效，请重新登录',
              icon: 'none',
              duration: 2000,
            })
            setTimeout(() => {
              setToken('')
              const route = useRoute()
              console.log('请求响应跳转')
              jumpLogin(route.path!, route.query)
            }, 1500)
          }
          return Promise.reject(error)
        } else if (error.statusCode === 401) {
          return Promise.reject(error)
        } else {
          // 如果返回false，则会调用Promise的reject回调，
          // 并将进入this.$u.post(url).then().catch(res=>{})的catch回调中，res为服务端的返回值
          if (error.data.msg !== null) {
            uni.showToast({
              title: error.data.msg,
              icon: 'none',
              duration: 2000,
            })
          }
          return Promise.reject(error)
        }
      }
    )
  }

  // * 常用请求方法封装
  get<T>(
    url: string,
    params?: object,
    _object: RequestConfig = {}
  ): Promise<ResultData<T>> {
    return this.service.get(url, params, _object)
  }

  post<T>(
    url: string,
    params?: object,
    _object: RequestConfig = {}
  ): Promise<ResultData<T>> {
    return this.service.post(url, params, _object)
  }

  put<T>(
    url: string,
    params?: object,
    _object: RequestConfig = {}
  ): Promise<ResultData<T>> {
    return this.service.put(url, params, _object)
  }

  delete<T>(
    url: string,
    params?: any,
    _object: RequestConfig = {}
  ): Promise<ResultData<T>> {
    return this.service.delete(url, params, _object)
  }
}

export default new RequestHttp(config)
