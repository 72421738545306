<script lang='ts' setup>
import { IDict } from '@/models'
import { useDictStore } from '@/store/modules/system/dict'
import { columnsItem } from '@/tmui/components/tm-picker-view/interface'
import { computed, onMounted, reactive, ref, watchEffect } from 'vue'

interface IProps {
	modelValue: string | undefined | string[]
	type: string
	exclude?: string[]
}

const props = defineProps<IProps>()

const emits = defineEmits(['update:modelValue'])

const { getDict } = useDictStore()

const listData = ref<IDict.Model[]>([])

const columns = computed(() => {
  return listData.value.map(item => {
    return { text: `${item.label}`, id: item.value }
  })
})

const pickerData = reactive<{
  show: boolean;
  modelValue?: (string | number)[]
  modelStr: string;
  columns: columnsItem[]
    }>({
      show: false,
      modelValue: [],
      modelStr: '',
      columns: []
    })

const handleOpenOptions = async () => {
  pickerData.show = true
}

const handlerPickerConfirm = () => {
  const { modelValue, modelStr } = pickerData
  pickerData.modelStr = modelStr
  emits('update:modelValue', modelValue![0])
}

const getList = async (type: string) => {
  listData.value = await getDict({ type }, props.exclude)
}

watchEffect(() => {
  if (props.modelValue && columns.value.length) {
    const dict = columns.value.find(item => item.id === props.modelValue)
    if (dict) {
      pickerData.modelStr = dict.text as string
    }
  }
})

onMounted(() => {
  getList(props.type)
})

</script>
<template>
  <view class="flex flex-row flex-row-center-between">
    <tm-input
      v-model="pickerData.modelStr"
      transprent
      placeholder="请选择"
      disabled
      style="flex: 1"
      @click="handleOpenOptions"
    ></tm-input>
    <tm-icon
      :user-interaction-enabled="false"
      :font-size="24"
      name="tmicon-angle-right"
      color="#E3E4E5"
    ></tm-icon>
    <tm-picker
      v-model:show="pickerData.show"
      v-model="pickerData.modelValue"
      :columns="columns"
      selected-model="id"
      @confirm="handlerPickerConfirm"
    ></tm-picker>
  </view>
</template>
<style lang='scss' scoped></style>
