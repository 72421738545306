<script lang='ts' setup>
import { computed } from 'vue'
// 公共组件
import tmImage from '@/tmui/components/tm-image/tm-image.vue'
// 页面组件
// hooks
// 工具
// model

interface IProps {
  src: string;
  name?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  name: ''
})

const imageSrc = computed(() => {
  if (props.src) {
    if (props.src.includes('http')) return props.src
    return import.meta.env.VITE_OSS_PATH + props.src
  } else {
    return ''
  }
})

</script>
<template>
  <view class="image-box">
    <tm-image v-bind="$attrs" :src="imageSrc"></tm-image>
    <text v-if="name" class="name">{{ name }}</text>
  </view>
</template>
<style lang='scss' scoped>
.image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    margin-top: 20rpx;
    font-size: 24rpx;
    color: #666666;
  }
}
</style>
