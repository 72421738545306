import type { App } from 'vue'
import {
  computed,
  reactive,
  toRefs,
} from 'vue'

import {
  createPinia,
  defineStore,
} from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

import { IUser } from '@/models/user'
import {
  getCache,
  setCache,
} from '@/utils/cache'

interface Location{
  latitude: number
  longitude: number
  province: string
  city: string
  district: string
  street: string
  streetNumber: string
  name: string
}

interface GlobalStore {
  location: Partial<Location>
  user: Partial<IUser.Model>
  pageLoading: boolean
  clinicId: string
  promoterUserId: string
  goodsData: any
  openId: string
}

export const useGlobalStore = defineStore(
  'globalStore',
  () => {
    const state = reactive<GlobalStore>({
      location: {
        latitude: undefined,
        longitude: undefined,
        city: '成都市',
        province: undefined,
        district: undefined,
        street: undefined,
        streetNumber: undefined,
        name: '蜜医健康',
      },
      user: {},
      pageLoading: false,
      clinicId: '',
      promoterUserId: '',
      goodsData: null,
      openId: '',
    })

    const getLocation = computed(() => {
      return state.location
    })

    const isLocation = computed(() => {
      return state.location.latitude && state.location.latitude
    })

    const getUser = computed(() => {
      return state.user
    })

    const getPageLoading = computed(() => {
      return state.pageLoading
    })

    const getClinicId = computed(() => {
      return state.clinicId
    })

    const getpromoterUserId = computed(() => {
      return state.promoterUserId
    })

    const getGoodsData = computed(() => {
      return state.goodsData
    })

    const getOpenId = computed(() => {
      return state.openId
    })

    const setLocation = (location: Partial<Location>) => {
      state.location = Object.assign(state.location, location)
    }

    const setUser = (user: Partial<IUser.Model>) => {
      state.user = Object.assign(state.user, user)
    }

    const setPageLoading = (loading: boolean) => {
      state.pageLoading = loading
    }

    const setClinicId = (clinicId: string) => {
      state.clinicId = clinicId
    }

    const setpromoterUserId = (promoterUserId: string) => {
      state.promoterUserId = promoterUserId
    }

    const setGoodsData = (goodsData: any) => {
      state.goodsData = goodsData
    }

    const setOpenId = (goodsData: any) => {
      state.openId = goodsData
    }

    return {
      ...toRefs(state),
      getLocation,
      isLocation,
      setLocation,
      getPageLoading,
      getUser,
      getClinicId,
      getpromoterUserId,
      getGoodsData,
      getOpenId,
      setUser,
      setPageLoading,
      setClinicId,
      setpromoterUserId,
      setGoodsData,
      setOpenId
    }
  },
  {
    persist: false,
  }
)

// piniaPersist(持久化)
const pinia = createPinia().use(
  createPersistedState({
    storage: {
      getItem(key: string): string | null {
        // return uni.getStorageSync(key)
        return getCache<string>(key)
      },
      setItem(key: string, value: string) {
        setCache(key, value)
        // uni.setStorageSync(key, value)
      },
    },
  })
)

export function setupStore(app: App<Element>) {
  app.use(pinia)
}

export { pinia }
