
<script lang="ts" setup name="UseDictTag">
import { useDictStore } from '@/store/modules/system/dict'
import { computed, onMounted, watch } from 'vue'

type menuType = 'tag' | 'text'

interface IProps {
	value?: string | string[]
	dictType: string
	type?: menuType
}

const props = withDefaults(defineProps<IProps>(), {
  value: '',
  type: 'text'
})

const { getDictItem, getDict } = useDictStore()

const dictItemList = computed(() => {
  if (props.value) {
    return getDictItem(props.dictType, props.value)
  } else {
    return []
  }
})

watch(() => props.dictType, (val) => {
  getDict({ type: val })
}, { deep: true })

onMounted(() => {
  getDict({ type: props.dictType })
})

</script>
<template>
  <view v-if="dictItemList && dictItemList.length > 1">
    <template v-for="(item, index) in dictItemList" :key="index">
      <!-- <el-tag v-if="type === 'tag'" :color="item.color" effect="dark">{{ item.label }}</el-tag> -->
      <text v-if="type === 'text'">{{ item.label }}</text>
    </template>
  </view>
  <template v-if="dictItemList && dictItemList.length === 1">
    <template v-for="(item, index) in dictItemList" :key="index">
      <!-- <el-tag v-if="type === 'tag'" :color="item.color" effect="dark">{{ item.label }}</el-tag> -->
      <text v-if="type === 'text'">{{ item.label }}</text>
    </template>
  </template>
  <template v-if="!dictItemList || dictItemList.length === 0">
    <!-- <el-tag v-if="type === 'tag'" effect="dark">--</el-tag> -->
    <text v-if="type === 'text'">--</text>
  </template>
</template>
<style lang="scss" scoped></style>
