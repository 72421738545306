<template>
  <tm-app ref="tmAppRef" :color="bgColor">
    <tm-skeleton v-if="loading && skeleton" :rows="10" :height="40"></tm-skeleton>
    <view v-else>
      <slot name="nav"></slot>
      <slot></slot>
    </view>
  </tm-app>
</template>
<script setup lang="ts">
import { useGlobalStore } from '@/store'
import TmApp from '@/tmui/components/tm-app/tm-app.vue'
import { ref, watch } from 'vue'

interface IProps {
  /** 背景色 */
  bgColor?: string
  skeleton?: boolean
  navBar?: boolean
}

// 接受父组件参数，配置默认值
withDefaults(defineProps<IProps>(), {
  bgColor: 'grey-4',
  skeleton: true,
  navBar: false
})

const loading = ref<boolean>(true)

const globalStore = useGlobalStore()

watch(() => globalStore.pageLoading, (value) => {
  if (!value && loading) {
    loading.value = value
  }
})

</script>

<style lang="scss" scoped></style>
