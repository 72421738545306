<template>
	<button
		@click="onclick"
		@touchstart="touchstart"
		@touchend="touchend"
		@longpress="emits('longpress', $event)"
		@touchcancel="isclickOn = false;emits('touchcancel', $event);"
		@touchmove="emits('touchmove', $event)"
		@getphonenumber="emits('getphonenumber', $event)"
		@error="emits('error', $event)"
		@opensetting="emits('opensetting', $event)"
		@launchapp="emits('launchapp', $event)"
		@contact="emits('contact', $event)"
		@chooseavatar="emits('chooseavatar', $event)"
		:form-type="props.formType"
		:openType="props.openType"
		:appParameter="props.appParameter"
		:sessionFrom="props.sessionFrom"
		:sendMessageTitle="props.sendMessageTitle"
		:sendMessagePath="props.sendMessagePath"
		:sendMessageImg="props.sendMessageImg"
		:sendMessageCard="props.sendMessageCard"
		:loading="_load"
		:disabled="_disabled"
		:group-id="props.groupId"
		:guild-id="props.guildId"
		:public-id="props.publicId"
		:hover-start-time="10000000"
		hover-stop-propagation
		:class="[
		  'button flex flex-row flex-row-center-center',
      isclickOn?props.hoverClass+' bhover':'',
      'webpc',
		  !isDisabledRoundAndriod ? `round-${btnSizeObj.round}` : '',
		   customClass
		]"
		:style="[
		  {
		    marginLeft: _margin[0] + 'rpx',
		    marginTop: _margin[1] + 'rpx',
		    marginRight: _margin[2] + 'rpx',
		    marginBottom: _margin[3] + 'rpx',
		    paddingLeft: _padding[0] + 'rpx',
		    paddingTop: _padding[1] + 'rpx',
		    paddingRight: _padding[2] + 'rpx',
		    paddingBottom: _padding[3] + 'rpx'
		  },
		 { height:btnSizeObj.h + props.unit,fontSize:btnSizeObj.fontSize+ props.unit,color:textColor,lineHeight:btnSizeObj.h + props.unit},
		  btnSizeObj.w&&!props.block ? { width: btnSizeObj.w + props.unit } : '',
		  tmcomputed.borderCss,
		  _bgcolor,
		  !_transprent && props.shadow > 0 ? tmcomputed.shadowColor : '',
		  customCSSStyle,
		]"

	>
		<slot>
			<tm-icon
				:lineHeight="btnSizeObj.fontSize * 0.9"
				v-if="_icon"
				:userInteractionEnabled="false"
				:color="_fontColor"
				:_class="_label ? 'pr-10' : ''"
				:unit="props.unit"
				:fontSize="btnSizeObj.fontSize * 0.9"
				:name="_icon"
			></tm-icon>




			{{_label}}

		</slot>
	</button>
	
</template>

<script lang="ts" setup>
/**
 * 按钮
 * @description 属性与原生按钮一致，对于微信登录授权进行了便捷封装。
 * @slot default 默认插槽。
 */
import { btnSize } from "./interface";
import { computed, PropType, ref, getCurrentInstance,provide } from "vue";
import tmSheet from "../tm-sheet/tm-sheet.vue";
import tmText from "../tm-text/tm-text.vue";
import tmIcon from "../tm-icon/tm-icon.vue";
import { cssstyle, tmVuetify } from "../../tool/lib/interface";
import {
  custom_props,
  computedTheme,
  computedClass,
  computedStyle,
  computedDark,
} from "../../tool/lib/minxs";
import { useTmpiniaStore } from "../../tool/lib/tmpinia";
const store = useTmpiniaStore();

/**
 * 事件说明
 * @description 事件属性与原生 一 致
 * @links 见官网：https://uniapp.dcloud.io/component/button.html
 */
const emits = defineEmits<{
  (e: "click", event: Event | TouchEvent): void;
  (e: "touchstart", event: Event | TouchEvent): void;
  (e: "touchmove", event: Event | TouchEvent): void;
  (e: "touchcancel", event: Event | TouchEvent): void;
  (e: "touchend", event: Event | TouchEvent): void;
  (e: "tap", event: Event | TouchEvent): void;
  (e: "longpress", event: Event | TouchEvent): void;
  (e: "getphonenumber", event: any): void;
  (e: "getUserInfo", event: any): void;
  (e: "getUserProfile", event: any): void;
  (e: "error", event: any): void;
  (e: "opensetting", event: any): void;
  (e: "launchapp", event: any): void;
  (e: "contact", event: any): void;
  (e: "chooseavatar", event: any): void;
}>();
const isnvue = ref(false);



const proxy = getCurrentInstance()?.proxy ?? null;
const props = defineProps({
  ...custom_props,
  transprent: {
    type: Boolean,
    default: false,
  },
  followTheme: {
    type: Boolean,
    default: true,
  },
  hoverClass: {
    type: String,
    default: "opacity-7",
  },
  /**
   * mini,small,normal,middle,large
   */
  size: {
    type: String as PropType<btnSize>,
    default: "normal",
  },
  fontSize: {
    type: Number,
    default: 0,
  },
  fontColor: {
    type: String,
    default: "",
  },
  margin: {
    type: Array as PropType<Array<number>>,
    default: () => [0, 16],
  },
  padding: {
    type: Array as PropType<Array<number>>,
    default: () => [0, 0],
  },
  //不是同层背景，默认是同层，为false
  //如果输入框表单与tmshee在同一层下。他们的黑白暗黑背景色是相同的。为了区分这个问题，需要单独指示，以便区分背景同层。
  //主意：它只在黑和白之间的色系才起作用，其它颜色下不起作用。
  noLevel: {
    type: Boolean,
    default: false,
  },
  shadow: {
    type: Number,
    default: 2,
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  //如果为true，采用块状flex布局，自动宽和高度。
  block: {
    type: Boolean,
    default: false,
  },
  round: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  //提供时，点击后会中转到对应页面。
  url: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  /**
   * submit,reset 在tm-form中使用。
   */
  formType: {
    type: String as PropType<"submit" | "reset" | "filterCancel" | "filterConfirm">,
    default: "",
  },
  //开放能力
  openType: {
    type: String,
    default: "",
  },
  //打开 APP 时，向 APP 传递的参数，open-type=launchApp时有效
  appParameter: {
    type: String,
    default: "",
  },
  sessionFrom: {
    type: String,
    default: "",
  },
  sendMessageTitle: {
    type: String,
    default: "",
  },
  sendMessagePath: {
    type: String,
    default: "",
  },
  sendMessageImg: {
    type: String,
    default: "",
  },
  sendMessageCard: {
    type: String,
    default: "",
  },
  groupId: {
    type: String,
    default: "",
  },
  guildId: {
    type: String,
    default: "",
  },
  publicId: {
    type: String,
    default: "",
  },
  unit: {
    type: String,
    default: "rpx",
  },
  darkBgColor: {
    type: String,
    default: "",
  },
});

/** -----------form专有------------ */
const formtype = computed(() => props.formType);
//父级方法。
let FormParent: any = null;
let FilterParent: any = null;
if (formtype.value == "reset" || formtype.value == "submit") {
  FormParent = proxy?.$parent;
  while (FormParent) {
    if (FormParent?.tmFormComnameId == "tmFormId" || !FormParent) {
      break;
    } else {
      FormParent = FormParent?.$parent ?? undefined;
    }
  }
}
//过滤器菜单 专用属性.
if (formtype.value == "filterCancel" || formtype.value == "filterConfirm") {
  FilterParent = proxy?.$parent;
  while (FilterParent) {
    if (FilterParent?.FilterMenu == "FilterMenu" || !FilterParent) {
      break;
    } else {
      FilterParent = FilterParent?.$parent ?? undefined;
    }
  }
}
/** -----------end------------ */
const _noLevel = computed(() => props.noLevel);
//是否暗黑模式。
const isDark = computed(() => computedDark(props, tmcfg.value));
// 设置响应式主题文字色。
let textColor = computed(() => {
  return tmcomputed.value.textColor;
});
//自定义样式：
const customCSSStyle = computed(() => {
  return {
    // height: btnSizeObj.value.h + props.unit,
    ...computedStyle(props),
	// fontSize:props.fontSize,
	// color:textColor,
    // border: "0px solid rgba(0, 0, 0, 0)",
    // background: "rgba(0, 0, 0, 0)",
    // borderRadius: "0px",
  };
});
//自定类
const customClass = computed(() => computedClass(props));
// 设置响应式全局组件库配置表。
const tmcfg = computed(() => store.tmStore);
//计算主题
const tmcomputed = computed<cssstyle>(() => {

  return computedTheme(
    { ...props },
    isDark.value,
    tmcfg.value
  );
});

const isclickOn = ref(false);
const _load = computed(() => props.loading);
const _disabled = computed(() => props.disabled);
const _label = computed(() => props.label);
const _icon = computed(() => props.icon);
const _isBorder = computed(() => {
  if (props.outlined && props.border == 0) return 1;
  if (props.border > 0) return props.border;
  return 0;
});
const sizeObj = computed(() => {
  if (props.unit == "px") {
    return {
      block: { w: 0, h: 80, fontSize: 28, round: 3 },
      mini: { w: 44, h: 18, fontSize: 10, round: 2 },
      small: { w: 60, h: 28, fontSize: 11, round: 3 },
      normal: { w: 110, h: 40, fontSize: 14, round: 3 },
      middle: { w: 180, h: 40, fontSize: 15, round: 3 },
      large: { w: 268, h: 44, fontSize: 16, round: 4 },
    };
  }
  return {
    block: { w: 0, h: 80, fontSize: 28, round: 3 },
    mini: { w: 88, h: 36, fontSize: 20, round: 2 },
    small: { w: 120, h: 56, fontSize: 22, round: 3 },
    normal: { w: 220, h: 80, fontSize: 28, round: 3 },
    middle: { w: 360, h: 80, fontSize: 30, round: 3 },
    large: { w: 535, h: 88, fontSize: 32, round: 4 },
  };
});
const btnSizeObj = computed(() => {
  let fontSize = props.fontSize || 0;

  if (props.block) {
    return {
      w: 0,
      h: props.height || sizeObj.value.block.h,
      fontSize: fontSize || sizeObj.value.block.fontSize,
      round: props.round == -1 ? 0 : props.round || sizeObj.value.normal.round,
    };
  }
  return {
    w: props.width || sizeObj.value[props.size].w,
    h: props.height || sizeObj.value[props.size].h,
    fontSize: fontSize || sizeObj.value[props.size].fontSize,
    round: props.round == -1 ? 0 : props.round || sizeObj.value[props.size].round,
  };
});
const _fontColor = computed(() => props.fontColor);
const _transprent = computed(() => props.transprent);
/** 数组是左，上，右，下顺序。 */
const _margin = computed(() => {
  if (props.margin.length == 1)
    return [props.margin[0], props.margin[0], props.margin[0], props.margin[0]];
  if (props.margin.length == 2)
    return [props.margin[0], props.margin[1], props.margin[0], props.margin[1]];
  if (props.margin.length == 3)
    return [props.margin[0], props.margin[1], props.margin[2], 0];
  if (props.margin.length == 4)
    return [props.margin[0], props.margin[1], props.margin[2], props.margin[3]];
  return [0, 0, 0, 0];
});
const _padding = computed(() => {
  if (props.padding.length == 1)
    return [props.padding[0], props.padding[0], props.padding[0], props.padding[0]];
  if (props.padding.length == 2)
    return [props.padding[0], props.padding[1], props.padding[0], props.padding[1]];
  if (props.padding.length == 3)
    return [props.padding[0], props.padding[1], props.padding[2], 0];
  if (props.padding.length == 4)
    return [props.padding[0], props.padding[1], props.padding[2], props.padding[3]];
  return [0, 0, 0, 0];
})

const _bgcolor = computed(() => {
  if (_transprent.value === true) return `background-color:rgba(255,255,255,0);`;
  if (props.darkBgColor !== "" && isDark.value === true) {
    return `background-color:${props.darkBgColor};`;
  }
  if (tmcomputed.value.gradientColor?.length === 2) {
    return tmcomputed.value.backgroundColorCss;
  }
  if (
    _noLevel.value &&
    tmcomputed.value.isBlackAndWhite === true &&
    isDark.value === true
  ) {
    return `background-color: ${tmcomputed.value.inputcolor}`;
  }
  return `background-color: ${tmcomputed.value.backgroundColor}`;
});









function touchstart(e: Event) {
  isclickOn.value = true;
  emits("touchstart", e);
}
function touchend(e: Event) {
  isclickOn.value = false;
  emits("touchend", e);
}

function onclick(e: Event) {
  if (
    FormParent != null &&
    typeof FormParent != "undefined" &&
    formtype.value &&
    !props.loading
  ) {
    FormParent[formtype.value]();
  }
  if (
    FilterParent != null &&
    typeof FilterParent != "undefined" &&
    formtype.value &&
    !props.loading
  ) {
    FilterParent[formtype.value]();
  }
  emits("click", e);
  if (props.url !== "" && typeof props.url === "string") {
    let url = props.url;
    if (url[0] !== "/") url = "/" + url;
    uni.navigateTo({
      url: url,
    });
    return;
  }
  if (props.openType == "getUserInfo" || props.openType == "getUserProfile") {
























  }
}


provide("appTextColor", textColor);
</script>

<style scoped>
.button {
	/* background: rgba(0, 0, 0, 0); */
	/* border: 0px solid rgba(0, 0, 0, 0); */
	padding: 0px;
	/* border-radius: 0px; */
}

.buttonHover {
	/* background: rgba(0, 0, 0, 0); */
}

.bhover {
	opacity: 0.7;
}

.webpc:hover{
  cursor: pointer;
	opacity: 0.7;

}


.bhover:hover{
	opacity: 0.7;
}
.button::after {
	background: transparent !important;
	background-color: transparent !important;
	border: none !important;
	border-radius: 0px !important;
}


</style>
